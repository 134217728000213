.card-img-top {
  background-color: #ccc;
  width: 100%;
  aspect-ratio: 4 / 3;
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

$primary: #cf805f;
$secondary: #bcc9d8;
$dark: #5e5e5e;

// Pretty sure web pack finds this module in nodemodules and expands it to:
// @import 'node_modules/bootstrap/scss/bootstrap.scss'
@import '~bootstrap'

